import { render, staticRenderFns } from "./contactdocument-close-flie.vue?vue&type=template&id=c39f4ab2&scoped=true&"
import script from "./contactdocument-close-flie.vue?vue&type=script&lang=js&"
export * from "./contactdocument-close-flie.vue?vue&type=script&lang=js&"
import style0 from "./contactdocument-close-flie.vue?vue&type=style&index=0&id=c39f4ab2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c39f4ab2",
  null
  
)

export default component.exports