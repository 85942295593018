<template>
	<el-dialog :title="'封闭文件'" :close-on-click-modal="false" :visible.sync="visible"
			   width="940px">
		<template>
			<el-table
					:data="fileList"
					style="width: 100%">
				<el-table-column
						type="index"
						label="序号"
						width="180">
				</el-table-column>
				<el-table-column
						prop="fileName"
						label="文件名称">
				</el-table-column>
				<el-table-column
						label="操作"
						width="180">
					<template slot-scope="scope">
						<el-button v-preventReClick type="primary" size="small" @click="selectFile(scope.row.filePathUrl)" v-if="scope.row.filePathUrl">下载</el-button>
					</template>
				</el-table-column>
			</el-table>
		</template>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "superdocument-audit",
		data() {
			return {
				visible: false,
				fileList: [],
				dataForm: {
					fileId: '',
				},
			}
		},
		methods: {
			selectFile(url) {
				window.open(url);
			},
			init(id) {
				this.dataForm.fileId = id?id:0;
				this.$nextTick(() => {
					this.visible = true;
					if (this.dataForm.fileId) {
						this.getDetail();
					}
					// this.$refs['dataForm'].resetFields();
				});
			},
			getDetail(){
				this.$http({
					url: this.$store.state.httpUrl + `/business/supervisioncontactinfo/closeFileList/${this.dataForm.fileId}`,
					method: "POST",
					params: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.fileList = data.body
					}
				});
			}
		}
	}
</script>
<style scoped>
	.selItemInput.descAreaLg {
		width: 640px !important;
	}
</style>
